// import { FavoriteBorderOutlined } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function AllActivitiesCard({
  id,
  title,
  image,
  width,
  imageBoxHeight,
  minCardHeight,
  price,
}) {
    const {t} = useTranslation();
  const navigation = useNavigate();
  const handleCardNavigation = () => {
    navigation(id);
  };
  return (
    <Card
      sx={{
        // Width: 300,
        minWidth: width,
        maxWidth: 350,
        minHeight: minCardHeight,
        maxHeight: 300,
        borderRadius: 5,
        // overflow: "hidden",
        boxShadow:
          "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
      }}
      onClick={handleCardNavigation}
    >
      {/* image */}
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          height: imageBoxHeight,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={image}
            alt="fishing"
            style={{
              width: "90%",
              objectFit: "cover",
              objectPosition: "center",
              border: 1,
              borderRadius: 30,
            }}
          />
        </Box>
      </Box>

      {/* content */}
      <CardContent
        sx={{
          padding: "8px 16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{}}>
          {/* title */}
          <Typography
            component="div"
            sx={{ fontWeight: "bold", color: "#5B9BD3", fontSize: "16px" }}
          >
            {title}
          </Typography>
        </Box>
        {/* rating */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "4px",
          }}
        >
          <Box>
            <Typography sx={{
                fontSize:'16px',
                fontWeight:'bold',

            }}>{price} {t('SAR')}</Typography>
          </Box>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            color: "#6B6B6B",
          }}>
            <StarIcon sx={{ fontSize: "18px", color: "#FFB400" }} />
            <Typography
              sx={{
                fontSize: "16px",
                marginLeft: "4px",
                color: "#FFB400",
                fontWeight: "light",
              }}
            >
              4.5
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default AllActivitiesCard;
