import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      activitySectionTitle: "Activity",
      upcomingOrderButtons: "Upcoming",
      previousOrderButtons: "Previous",
      singleActivityCompanyCard: "Company",
      singleActivityStatusCard: "Status",
      singleActivityCategoryCard: "Category",
      singleActivityPageSnapShotsTitle: "Snapshots from our guests",
      SingelActivityPageRatingAndReviewTitle: "Ratings & Reviews ",
      OrderRegistrationPageTitle: "Registration",
      OrderBookingPageTitle: "Booking",
      OrderPaymentPageTitle: "Payment",
      OrderPageTitle: "Booking Information",
      NextButtonText: "Next",
      OrderButtonText: "ORDER",
      ReORderButtonText: "REORDER",
      CancelButtonText: "CANCEL",
      OrderButtonMessage: "10 people have booked today",
      NumberOfTickets: "Number of tickets",
      AgreeToTermsAndConditions: "Agree to Terms & Conditions",
      Total: "Total",
      PaymentMethods: "Payment Methods",
      CashOnDelivery: "Cash on delivery",
      VisaOrMasterCard: "Visa or Master Card",
      ApplePay: "Apple Pay",
      Coupon: "Coupon",
      AddCoupon: "Add Coupon",
      ApplyButtonText: "APPLY",
      PayNow: "PAY NOW",
      SuccessMessageTitle: "SUCCESS",
      SuccessMessage: "Congratulations, your order was successfully completed",
      Done: "Done",
      LogoutTitle: "Logout",
      LogoutButtonTitle: "Log out",
      LoginAsCustomer: "Login as Customer",
      PhoneNumber: "Phone Number",
      Password: "Password",
      Login: "Login",
      DontHaveAnAcount: "Don't have an account",
      Registration: "Registration",
      CancelMessage: "This order has been canceled",
      CancelTitle: "CANCELED",
      ConfirmCancelMessage: "Are you sure you want to cancel this order?",
      Confirm: "Confirm",
      FailedToCancelMessage: "Failed to cancel this Order",
      FailedMessageTitle: "Failed",
      Language: "Language",
      English: "English",
      Arabic: "Arabic",
      SAR: "SAR",
      TicketsLeft: "Tickets left",
      perTicket: "Per Ticket",
      Loading: "Loading",
      OrderPageErrorMessage: "An error occurred while fetching your orders!",
      ExploreEvents: "Explore Events",
      SeeUpcomingEvents: "See all upcoming events",
      Available: "Available",
      NoUpcomingYet: "There is no upcoming events available",
      NoPreviousYet: "There is no previous events available",
      ExploreUpcomingEvents: "Explore Upcoming Events",
      Male: "Male",
      Female: "Female",
      PreferNotToSay: "prefer not to say",
      Age: "Age",
      Gender: "Gender",
      Email: "Email",
      DateOfBirth: "Date of Birth",
      NationalID: "National ID/Passport",
      EmailConfirmation: "Email Confirmation",
      Country: "Country",
      FullNameIsRequired: "Full Name is required",
      CountryIsRequired: "Country is required",
      CityIsRequired: "City is required",
      AgeIsRequired: "Age is required",
      GenderIsRequired: "Gender is required ",
      EmailIsRequired: "Email is required",
      EmailConfirmationIsRequired: "Email Confirmation is required",
      EmailDoNotMatch: "Emails do not match",
      SeeMore: "See More",
      SeeLess: "See Less",
      Category: "Category",
      Categories: "Categories",
      SpecialOffers: "Special offers",
      hi: "Hi",
      TrendingActivitiesNearby: "Trending Activities Nearby",
      Explore: "Explore",
      Cities: "Cities",
      Search: "Search",
      TopCities: "Top Cities",
      NoResultsFound: "No Results Found",
      UploadImage: "Upload Image",
      Upload: "UPLOAD ",
      Ratings: "Ratings",
      AddNotesAndComments: "Add Notes and Comments",
      WriteItHere: "Write it here",
      Send: "Send",
      Sending: "Sending",
      Popular: "Popular",
      Edit: "Edit",
      SeeAll: "See All",
      RatingAndReviews: "Ratings & Reviews",
      Reviews: "Reviews",
      BoardingScreen1Title: "Discover Exciting Adventures",
      BoardingScreen1Subtitle:
        "Explore a wide range of activities, from concerts to outdoor adventures, and make the most of your time in Saudi Arabia.",
      BoardingScreen2Title: "Plan Your Next Experience",
      BoardingScreen2SubTitle:
        "Easily browse and book experiences, track your reservations, and get ready for unforgettable moments.",
      BoardingScreen3Title: "Join the Fun",
      SignUp: "Sign Up",
      Next: "Next",
      Skip: "Skip",
      ContinueAsGuest: "Continue as guest",
    },
  },
  ar: {
    translation: {
      activitySectionTitle: "نشاطات",
      upcomingOrderButtons: "القادم",
      previousOrderButtons: "السابق",
      singleActivityCompanyCard: "الشركه",
      singleActivityStatusCard: "الحاله",
      singleActivityCategoryCard: "النوع",
      singleActivityPageSnapShotsTitle: "لقطات من ضيوفنا",
      SingelActivityPageRatingAndReviewTitle: "التقييمات والمراجعات ",
      OrderRegistrationPageTitle: "التسجيل",
      OrderBookingPageTitle: "الحجز",
      OrderPaymentPageTitle: "الدفع",
      OrderPageTitle: "معلومات الحجز",
      NextButtonText: "التالي",
      OrderButtonText: "اطلب",
      ReORderButtonText: "إعادة الطلب",
      CancelButtonText: "إلغاء",
      OrderButtonMessage: "10 أشخاص قاموا بالحجز اليوم",
      NumberOfTickets: "عدد التذاكر",
      AgreeToTermsAndConditions: "الموافقة على الشروط والأحكام",
      Total: "الإجمالي",
      PaymentMethods: "طرق الدفع",
      CashOnDelivery: "الدفع عند الاستلام",
      VisaOrMasterCard: "فيزا أو ماستركارد",
      ApplePay: "أبل باي",
      Coupon: "قسيمة",
      AddCoupon: "أضف قسيمة",
      ApplyButtonText: "تطبيق",
      PayNow: "ادفع الآن",
      SuccessMessageTitle: "نجاح",
      SuccessMessage: "تهانينا، تم إكمال طلبك بنجاح",
      Done: "تم",
      LogoutTitle: "تسجيل الخروج ",
      LogoutButtonTitle: "تسجيل الخروج",
      LoginAsCustomer: "تسجيل الدخول كعميل",
      PhoneNumber: "رقم الهاتف",
      Password: "كلمة المرور",
      Login: "تسجيل الدخول",
      DontHaveAnAcount: "ليس لديك حساب؟",
      Registration: "التسجيل",
      CancelMessage: "تم إلغاء هذا الطلب",
      CancelTitle: "إلغاء",
      ConfirmCancelMessage: "هل أنت متأكد أنك تريد إلغاء هذا الطلب؟",
      Confirm: "تأكيد",
      FailedToCancelMessage: "فشل في إلغاء هذا الطلب",
      FailedMessageTitle: "فشل",
      Language: "اللغة",
      English: "الإنجليزية",
      Arabic: "العربية",
      SAR: "ر.س",
      TicketsLeft: "التذاكر المتبقية",
      perTicket: "للتذكره",
      Loading: "جارٍ التحميل",
      OrderPageErrorMessage: "حدث خطأ أثناء جلب طلباتك!",
      ExploreEvents: "استكشاف الفعاليات",
      SeeUpcomingEvents: "عرض جميع الفعاليات القادمة",
      Available: "متاح",
      NoUpcomingYet: "لا يوجد حدث قادم متاح",
      NoPreviousYet: "لا يوجد حدث سابق متاح",
      ExploreUpcomingEvents: "استكشاف الأحداث القادمة",
      Male: "ذكر",
      Female: "أنثى",
      PreferNotToSay: "أفضل عدم الإفصاح",
      Age: "العمر",
      Gender: "النوع",
      Email: "البريد الإلكتروني",
      DateOfBirth: "تاريخ الميلاد",
      NationalID: "رقم الهوية/جواز السفر",
      EmailConfirmation: "تأكيد البريد الإلكتروني",
      Country: "البلد",
      FullNameIsRequired: "الاسم الكامل مطلوب",
      CountryIsRequired: "البلد مطلوب",
      CityIsRequired: "المدينة مطلوبة",
      AgeIsRequired: "العمر مطلوب",
      GenderIsRequired: "النوع مطلوب",
      EmailIsRequired: "البريد الإلكتروني مطلوب",
      EmailConfirmationIsRequired: "تأكيد البريد الإلكتروني مطلوب",
      EmailDoNotMatch: "البريد الإلكتروني غير متطابق",
      SeeMore: "عرض المزيد",
      SeeLess: "عرض أقل",
      Category: "الفئة",
      Categories: "الفئات",
      SpecialOffers: "عروض خاصة",
      Hi: "مرحبًا",
      TrendingActivitiesNearby: "أنشطة رائجة بالقرب منك!",
      Explore: "استكشف",
      Cities: "المدن",
      Search: "ابحث",
      TopCities: "اشهر المدن",
      NoResultsFound: "لم يتم العثور على نتائج",
      UploadImage: "تحميل صورة",
      Upload: "تحميل",
      Ratings: "التقييمات",
      AddNotesAndComments: "أضف ملاحظات وتعليقات",
      Send: "إرسال",
      Sending: "جارٍ الإرسال",
      WriteItHere: "اكتب هنا",
      Popular: "الاشهر",
      SeeAll: "عرض الكل",
      RatingAndReviews: "التقييمات والمراجعات",
      Reviews: "المراجعات",
      BoardingScreen1Title: "اكتشف مغامرات شيقة",
      BoardingScreen1Subtitle:
        "ستمتع بفعاليات متنوعة في جميع أنحاء المملكة العربية السعودية",
      BoardingScreen2Title: "خطط لتجربتك القادمة",
      BoardingScreen2Subtitle:
        "تصفح واحجز الفعاليات بسهولة، تابع حجوزاتك، واستعد لأوقات لا تُنسى",
      BoardingScreen3Title: "انضم إلى المغامرة",
      SignUp: "انشاء حساب",
      Next: "التالي",
      Skip: "تخطي",
      ContinueAsGuest: "المتابعة كضيف",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
