import { Box, Container, Typography } from "@mui/material";
import React, {useState } from "react";
import ActivityImageCarousel from "../components/activityComponents/ActivityImageCarousel";
import ActivityHeader from "../components/activityComponents/ActivityHeader";
import ActivityBookNowSection from "../components/activityComponents/ActivityBookNowSection";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFetchSingleEventDetails from "../hooks/useFetchSingleEventDetails";
import styles from "./SingleActivityPage.module.css";
import { cancelOrder } from "../services/guest";
import { useTranslation } from "react-i18next";
import MessagePopup from "./BookingSuccessPopup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import ConfirmCancelPopup from "../components/ConfirmCancelPopup";
import FailedToCancel from "../components/FailedToCancel";

function SingleOrderCancelationPage() {
  // handle cancel popup
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false); // State for success popup
  const [failedToCancelPopup, setFailedToCancelPopup] = useState(false); // State for failed

  const { activityId } = useParams();
  const { orderId } = useParams();
  console.log("order id", orderId);
  const { eventDetails } = useFetchSingleEventDetails(activityId);
  const navigate = useNavigate();

  // scroll function
  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  // cancel order function
  const token = atob(localStorage.getItem("authToken"));
  console.log("token", token);
  // cancel function
  const handleCancelOrder = async (orderId) => {
    try {
      const res = await cancelOrder(orderId, token);

      if (res.status === "Failed") {
        setFailedToCancelPopup(true); // Show failed popup
        setConfirmCancel(false);
      } else {
        setSuccessPopupOpen(true); // Show success popup
        setConfirmCancel(false);
      }

      console.log("cancel order response", res);
    } catch (error) {
      console.error("Error cancelling order:", error);
      setFailedToCancelPopup(true);
      setConfirmCancel(false);
    }
  };

  // open confirm cancel popup
  const handleOpenConfirmCancel = () => {
    setConfirmCancel(true);
  };
  // close confirm cancel popup
  const handleCloseConfirmCancel = () => {
    setConfirmCancel(false);
  };

  // handle confirm cancel
  const handleConfirmCancel = () => {
    handleCancelOrder(orderId);
  };

  // close cancel popup
  const handleClosePopup = () => {
    setSuccessPopupOpen(false);
    navigate(`/orders`);
  };
  const handleCloseFailedToCancelPopup = () => {
    setFailedToCancelPopup(false);
  };

  const location = useLocation();
  const { orderStatus } = location.state;
  console.log(orderStatus);

  const images = [
    {
      id: "1",
      url: eventDetails?.image,
    },
    {
      id: "2",
      url: eventDetails?.image,
    },
    {
      id: "1",
      url: eventDetails?.image,
    },
    {
      id: "2",
      url: eventDetails?.image,
    },
  ];

  // translation
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        marginBottom: "150px",
      }}
    >
      <ActivityImageCarousel images={images} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          overflow: "hidden",
          marginTop: "20px",
        }}
      >
        {/* image carousel */}
        {/* activity details */}
        <ActivityHeader
          name={eventDetails?.title}
          description={eventDetails?.description}
          city={eventDetails?.address_title}
          country={eventDetails?.country}
          price={eventDetails?.price}
          locationUrl={eventDetails?.google_maps_location}
          orderName={orderId}
          category={eventDetails?.category}
          company={eventDetails?.company_name}
          status={orderStatus}
        />
        {/* snapshots */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {eventDetails?.feedbacks?.some(
            (feedback) => feedback.feedback_image
          ) && (
            <>
              <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                {t("singleActivityPageSnapShotsTitle")}
              </Typography>
              <div className={styles.snapshotContainer} onWheel={handleScroll}>
                {eventDetails.feedbacks.map((feedback, index) =>
                  feedback.feedback_image ? (
                    <div className={styles.snapshotItem} key={index}>
                      <img
                        className={styles.snapshotImage}
                        src={feedback.feedback_image}
                        alt="snapshot"
                      />
                    </div>
                  ) : null
                )}
              </div>
            </>
          )}
        </Box>

        {/* testimonials */}
        {/* <Box>
          <CardCarouselSection title={"Ratings & Reviews "} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginBottom: "150px",
            }}
          >
            {eventDetails?.feedbacks.map((feedback, index) => (
              <ActivityTestimonial
                customerName={feedback.customer_name}
                feedback={feedback.feedback}
                rating={feedback.rating}
                key={index}
              />
            ))}
          </Box>
        </Box> */}
        {/* book now */}
        <ActivityBookNowSection
          title={t("CancelButtonText")}
          handleCancelOrder={handleOpenConfirmCancel}
          orderId={orderId}
          type={"cancel"}
        />
      </Container>
      {/* confirm cancel */}
      <ConfirmCancelPopup
        open={confirmCancel}
        onClose={handleCloseConfirmCancel}
        onConfirm={handleConfirmCancel}
      />
      {/* cancel */}
      <MessagePopup
        open={successPopupOpen}
        handleClose={handleClosePopup}
        messageTitle={t("CancelTitle")}
        messageText={t("CancelMessage")}
        icon={<CheckCircleIcon sx={{ fontSize: 60, color: "#7AC78E" }} />}
        type={"cancel"}
      />
      <FailedToCancel
        open={failedToCancelPopup}
        onClose={handleCloseFailedToCancelPopup}
      />
    </Box>
  );
}

export default SingleOrderCancelationPage;
