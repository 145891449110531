import { Box, Container, IconButton, Typography } from "@mui/material";
import React from "react";

import CardCarouselSection from "../components/CardCarouselSection";
import ActivityTestimonial from "../components/activityComponents/ActivityTestimonial";
import { useNavigate, useParams } from "react-router-dom";
import useFetchSingleEventDetails from "../hooks/useFetchSingleEventDetails";
import styles from "./SingleActivityPage.module.css";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RatingsReviews from "../components/RatingAndReview";

function ReviewInterfacePage() {
  const { activityId } = useParams();
  const { eventDetails } = useFetchSingleEventDetails(activityId);
  console.log("event details", eventDetails);

  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("language");

  const navigate = useNavigate();

  const goBackHome = () => {
    navigate(-1);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        paddingY: "50px",
      }}
    >
      {/* title with back button */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          gutterBottom
          alignSelf={"start"}
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            )}
          </IconButton>
          {t("RatingAndReviews")} ({eventDetails?.feedbacks?.length})
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RatingsReviews
          ratingObject={eventDetails?.rating_summary}
          activityId={activityId}
        />
        <hr
          style={{
            border: "none", // Ensures no border is displayed
            width: "90%",
            height: "0", // Ensures the hr's height doesn't interfere
            borderTop: "1px solid rgba(139,139,139,50%)", // Creates a visible line
          }}
        />
      </Box>
      {/* snapshots */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {eventDetails?.feedbacks?.some(
          (feedback) => feedback.feedback_image
        ) && (
          <>
            <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
              {t("singleActivityPageSnapShotsTitle")}
            </Typography>
            <div className={styles.snapshotContainer} onWheel={handleScroll}>
              {eventDetails.feedbacks.map((feedback, index) =>
                feedback.feedback_image ? (
                  <div className={styles.snapshotItem} key={index}>
                    <img
                      className={styles.snapshotImage}
                      src={feedback.feedback_image}
                      alt="snapshot"
                    />
                  </div>
                ) : null
              )}
            </div>
          </>
        )}
      </Box>
      {/* feedbacks  */}
      <Box>
        <CardCarouselSection title={t("RatingAndReviews")} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {eventDetails?.feedbacks.map((feedback, index) => (
            <ActivityTestimonial
              customerName={feedback.customer_name}
              feedback={feedback.feedback}
              rating={feedback.rating}
              key={index}
              reviewTime={feedback.formatted_review_time}
            />
          ))}
        </Box>
      </Box>{" "}
    </Container>
  );
}

export default ReviewInterfacePage;
