// Import axios for making HTTP requests
import axios from "axios";
// import { useDispatch } from "react-redux";

// Base URL for the application
const BASE_URL = process.env.REACT_APP_API_URL;

// Create an instance of axios with the base URL configured
const apiClient = axios.create({ baseURL: BASE_URL });

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Get the auth token from local storage
    const authToken = localStorage.getItem("authToken");

    // If the auth token exists, decode it from base64 and add it to the request headers
    if (authToken) {
      // Decode the base64 token
      const decodedAuthToken = atob(authToken);

      // Set the decoded token in the request headers
      config.headers["X-Yalla-Auth"] = decodedAuthToken;
    }

    // Return the modified config
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export { apiClient }; // Export the configured axios instance

/**
 * Attempts to log in to yallafalla application using the provided credentials.
 * @param {string} phoneNumber - The mobile number of the user.
 * @param {string} password - The password of the user.
 * @returns {Promise<string[]>} - Returns an array of cookies on success, otherwise throws an error.
 */

export const signup = async (firstName, lastName, email, city) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      city: city,
    };
    const url = `${BASE_URL}/api/method/yallafalla.auth.api.signup`;
    const response = await apiClient.post(url, payload, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });

    if (response.data.message.data.otp) {
      console.log(
        "Developer mode detected, OTP: ",
        response.data.message.data.otp
      );
    }

    return response.data;
  } catch (error) {
    console.error("Error signing up:", error);
    throw error;
  }
};

export const login = async (email, password) => {
  const currentLanguage = localStorage.getItem("language");

  try {
    // Construct the payload
    const payload = {
      email: email,
      password: password,
    };

    // Make the POST request to the Frappe login API
    const response = await apiClient.post(
      `${BASE_URL}/api/method/yallafalla.auth.api.customer_login`,
      payload,
      {
        headers: {
          "Accept-Language": currentLanguage,
        },
      }
    );

    // On success, extract the token, encode it, and store it in localStorage
    if (response.status === 200) {
      const tokenEncoded = btoa(response.data.message.token); // Encode the token in base64
      localStorage.setItem("authToken", tokenEncoded);

      console.log("tokenEncoded", tokenEncoded);
      return response.data;
    }

    // If there's an issue, throw an error
    throw new Error("Failed to login to the application");
  } catch (error) {
    // Handle any errors that occur during the login process
    throw error;
  }
};

export const generateOTP = async (email) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const payload = {
      email: email,
    };

    const url = `${BASE_URL}/api/method/yallafalla.auth.api.generate_otp`;
    const response = await apiClient.post(url, payload, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });

    if (response.data.message.otp) {
      console.log("Developer mode detected, OTP: ", response.data.message.otp);
    }

    return response.data;
  } catch (error) {
    console.error("Error generating OTP:", error);
    throw error;
  }
};

export const verifyOTP = async (email, otp) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const payload = {
      email: email,
      otp: otp,
    };
    const url = `${BASE_URL}/api/method/yallafalla.auth.api.verify_otp`;
    const response = await apiClient.post(url, payload, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    throw error;
  }
};

export const setPassword = async (email, key, password) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.auth.api.set_password`;
    const payload = {
      email: email,
      key: key,
      new_password: password,
    };

    const response = await apiClient.post(url, payload, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error setting password:", error);
    throw error;
  }
};

/**
 * Attempts to get user orders from yallafalla backend.
 * @returns {Promise<object[]>} - Returns an array of objects on success, otherwise throws an error.
 */
export const fetchUserOrders = async () => {
  const currentLanguage = localStorage.getItem("language");
  try {
    // Replace '{{base_url}}' with the actual base URL if it's not dynamically available
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_orders?page=1&count=5`;
    const response = await apiClient.get(
      url,
      { withCredentials: true },
      {
        headers: {
          "Accept-Language": currentLanguage,
        },
      }
    );

    return response.data;
  } catch (error) {
    // Log any errors that occur during the fetch operation
    console.error("Error fetching user orders:", error);
    throw error;
  }
};

/**
 * Checks if the user is currently logged in by examining the authentication token stored in localStorage.
 * This function decodes the token to read its payload and verifies if the token has expired.
 * If the token is missing, invalid, or expired, the function returns false, indicating the user is not logged in.
 * Otherwise, it returns true, indicating the user is logged in.
 *
 * @returns {boolean} True if the user is logged in, false otherwise.
 */
export const isLoggedIn = () => {
  let token = localStorage.getItem("authToken");

  // Check if the token exists
  if (!token) {
    return false;
  }

  // Base64 decode for the token.
  token = atob(token);

  // Decode the token without verifying the signature (this is just for reading the payload)
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  // Parse the JSON payload
  const payload = JSON.parse(jsonPayload);

  // Check if the token has expired
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  if (payload.exp && currentTime > payload.exp) {
    return false;
  }

  return true;
};
