import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./BookingRegistrationPage.module.css";
import MaleIcon from "@mui/icons-material/Male";
// import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import EmailIcon from "@mui/icons-material/Email";
// import LockIcon from "@mui/icons-material/Lock";
import BadgeIcon from "@mui/icons-material/Badge";
import { MuiTelInput } from "mui-tel-input";
import BookingPagesHeader from "../components/BookingPagesHeader";
import { useForm } from "react-hook-form";
import useFetchCountries from "../hooks/useFetchCountries";
import useFetchCities from "../hooks/useFetchCities";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { firstTimeBooking } from "../services/guest";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";

function BookingRegistrationPage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  console.log(user);
  // const customer_data = user.customer_data?.message?.customer_data;
  const customerName = localStorage.getItem("customer_name");
  const customerPhone = localStorage.getItem("mobile_number");
  const customerCountry = localStorage.getItem("country");
  const customerEmail = localStorage.getItem("email");
  console.log(customerCountry);

  const phoneValue = watch("phone");
  const selectedCountry = watch("country");
  const { countries } = useFetchCountries();
  const { cities } = useFetchCities();

  const navigate = useNavigate();
  const param = useParams();
  //get the current location
  const location = useLocation();
  const path = location.pathname;
  const currentPage = path.split("/").pop();
  console.log("current page:", currentPage);

  useEffect(() => {
    if (customerCountry) {
      setValue("country", customerCountry);
    }
  }, [customerCountry, setValue]);

  // console.log(param)
  const onSubmit = async (data) => {
    try {
      const nid = data.id;
      const city = data.city;
      const gender = data.gender;
      const age = data.age;
      const email = data.email;
      const token = localStorage.getItem("authToken");
      const tokenDecoded = atob(token);
      console.log("token from first time:", tokenDecoded);
      const response = await firstTimeBooking(
        nid,
        city,
        gender,
        age,
        email,
        tokenDecoded
      );
      navigate(`/${param.activityId}/booking/booking-information`, {
        state: data,
      });
      localStorage.setItem(
        "first_time_booking",
        response.data.first_time_booking
      );
      console.log("data form submit", response.data.first_time_booking);
      return response;
    } catch (error) {
      console.error("Error booking event:", error);
    }
    // Handle form submission
  };

  return (
    <Box>
      <BookingPagesHeader currentPage={currentPage} />
      <Container
        sx={{
          marginY: "50px",
        }}
      >
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          {/* name */}
          <div className={styles.formGroup}>
            <label>
              <PersonIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <input
              value={customerName}
              type="text"
              name="fullName"
              placeholder="Full Name"
              {...register("fullName", {
                required: {
                  value: true,
                  message: t("FullNameIsRequired"),
                },
              })}
            />
            {errors.fullName && <span>{errors.fullName.message}</span>}
          </div>
          {/* phone */}
          <div
            style={{
              width: "95%",
            }}
          >
            <MuiTelInput
              
              InputProps={{
                sx: {
                  borderRadius: "50px",
                  border: "0px",
                },
              }}
              defaultCountry="SA"
              fullWidth
              margin="normal"
              value={phoneValue || customerPhone}
              onChange={(value) =>
                setValue("phone", value, { shouldValidate: true })
              }
            />
            {errors.phone && <span>{errors.phone.message}</span>}
          </div>
          {/* country */}
          <div className={styles.formGroup}>
            <select
              name="country"
              {...register("country", {
                required: {
                  value: true,
                  message: t("CountryIsRequired"),
                },
              })}
              value={selectedCountry || ""}
              onChange={(e) =>
                setValue("country", e.target.value, {
                  shouldValidate: true,
                })
              }
            >
              <option
                className={styles.firstOption}
                value=""
                disabled
                selected
                hidden
              >
                {t("Country")}
              </option>
              {countries.map((country) => (
                <option key={country.name} value={country.name}>
                  {country.name}
                </option>
              ))}
              {/* Add country options here */}
            </select>
            {errors.country && (
              <span className={styles.errorMessage}>
                {errors.country.message}
              </span>
            )}
          </div>
          {/* city and age */}
          <div className={styles.formRow}>
            <div className={styles.formGroup} style={{ flex: "0 0 60%" }}>
              <select
                name="city"
                {...register("city", {
                  required: {
                    value: true,
                    message: t("CityIsRequired"),
                  },
                })}
              >
                <option
                  className={styles.firstOption}
                  value=""
                  disabled
                  selected
                  hidden
                >
                  City
                </option>
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.city_name}
                  </option>
                ))}
                {/* Add city options here */}
              </select>
              {errors.city && (
                <span className={styles.errorMessage}>
                  {errors.city.message}
                </span>
              )}
            </div>
            <div className={styles.formGroup} style={{ flex: "0 0 30%" }}>
              <input
                type="number"
                name="age"
                placeholder={t("Age")}
                {...register("age", {
                  required: {
                    value: true,
                    message: t("AgeIsRequired"),
                  },
                })}
              />
              {errors.age && (
                <span className={styles.errorMessage}>
                  {errors.age.message}
                </span>
              )}
            </div>
          </div>
          {/* gender */}
          <div className={styles.formGroup}>
            <label htmlFor="gender">
              <MaleIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <select
              name="gender"
              {...register("gender", {
                required: {
                  value: true,
                  message: t("GenderIsRequired"),
                },
              })}
            >
              <option
                className={styles.firstOption}
                value=""
                disabled
                selected
                hidden
              >
                {t("Gender")}
              </option>
              <option value="male">{t("Male")}</option>
              <option value="female">{t("Female")}</option>
              <option value="Prefer not to say">{t("PreferNotToSay")}</option>
            </select>
            {errors.gender && (
              <span className={styles.errorMessage}>
                {errors.gender.message}
              </span>
            )}
          </div>
          {/* id */}
          <div className={styles.formGroup}>
            <label>
              <BadgeIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <input
              type="text"
              name="id"
              placeholder={t("NationalID")}
              {...register("id", { required: "ID is required" })}
            />
            {errors.id && (
              <span className={styles.errorMessage}>{errors.id.message}</span>
            )}
          </div>
          {/* email */}
          <div className={styles.formGroup}>
            <label>
              <EmailIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <input
              type="email"
              name="email"
              value={customerEmail}
              readOnly
              placeholder={t("Email")}
              {...register("email", {
                required: {
                  value: true,
                  message: t("EmailIsRequired"),
                },
              })}
            />
            {errors.email && (
              <span className={styles.errorMessage}>
                {errors.email.message}
              </span>
            )}
          </div>
          {/* confirm email */}
          <div className={styles.formGroup}>
            <label htmlFor="emailConfirmation">
              <EmailIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <input
              type="email"
              name="emailConfirmation"
              value={customerEmail}
              readOnly
              placeholder={t("EmailConfirmation")}
              {...register("emailConfirmation", {
                required: {
                  value: true,
                  message: t("EmailConfirmationIsRequired"),
                },
                validate: (value) =>
                  value === watch("email") || t("EmailDoNotMatch"),
              })}
            />
            {errors.emailConfirmation && (
              <span className={styles.errorMessage}>
                {errors.emailConfirmation.message}
              </span>
            )}
          </div>
          {/* password */}
          {/* <div className={styles.formGroup}>
            <label htmlFor="password">
              <LockIcon />
            </label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              {...register("password", { required: "Password is required" })}
            />
            {errors.password && (
              <span className={styles.errorMessage}>
                {errors.password.message}
              </span>
            )}
          </div> */}
          {/* confirm password */}
          {/* <div className={styles.formGroup}>
            <label htmlFor="passwordConfirmation">
              <LockIcon />
            </label>
            <input
              type="password"
              name="passwordConfirmation"
              placeholder="Password Confirmation"
              {...register("passwordConfirmation", {
                required: "Password Confirmation is required",
                validate: (value) =>
                  value === watch("password") || "Passwords do not match",
              })}
            />
            {errors.passwordConfirmation && (
              <span className={styles.errorMessage}>
                {errors.passwordConfirmation.message}
              </span>
            )}
          </div> */}
          {/* next button */}
          <button type="submit" className={styles.submitButton}>
            {t("NextButtonText")}
          </button>
        </form>
      </Container>
    </Box>
  );
}

export default BookingRegistrationPage;
