import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import EventsPage from "./pages/EventsPage";
import SupportPage from "./pages/SupportPage";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import VerifyOtpPage from "./pages/VerifyOtpPage";
import SetPassword from "./pages/SetPassword";
import ExplorePage from "./pages/ExplorePage";
import ExploreCityPage from "./pages/ExploreCityPage";
import SingleActivityPage from "./pages/SingleActivityPage";
import BottomNavBar from "./components/BottomNavBar";
import OrderDetailPage from "./pages/OrderDetailPage";
import ReviewInterfacePage from "./pages/ReviewInterfacePage";
import OrderReviewPage from "./pages/OrderReviewPage";
import OrdersPage from "./pages/OrdersPage";
import OrdersPrevious from "./pages/OrdersPrevious";
import OrdersUpcoming from "./pages/OrdersUpcoming";
import BookingInformation from "./pages/BookingInformation";
import BookingRegistrationPage from "./pages/BookingRegistrationPage";
import BookingPaymentPage from "./pages/BookingPaymentPage";
import SingleActivityReOrderPage from "./pages/SingleActivityReOrderPage";
import SingleOrderCancelationPage from "./pages/SingleOrderCancelationPage";
import ProfilePage from "./pages/ProfilePage";
import { LanguageProvider } from "./services/LanguageContext";
import AllActivitiesPage from "./pages/AllActivitiesPage";
import HomePage from "./pages/HomePage";
import Introduction from "./pages/Introduction";

const App = () => {
  const location = useLocation();
  const isLoggedIn = !!localStorage.getItem("token");
  const isGuest = !!localStorage.getItem("guest");

  useEffect(() => {
    if (!isLoggedIn && !isGuest && location.pathname !== "/introduction") {
      // Redirect to Introduction page if the user is not logged in or a guest
      window.location.href = "/introduction";
    }
  }, [isLoggedIn, isGuest, location.pathname]);

  const bottomNavBarPaths = [
    "/",
    "/explore",
    "/explore-city",
    "/rating",
    "/rating-review",
    "/orders/previous",
    "/orders/upcoming",
    "/logout",
  ];

  const shouldShowBottomNavBar = bottomNavBarPaths.includes(location.pathname);

  return (
    <>
      <Routes>
        <Route path="/introduction" element={<Introduction />} />

        <Route path="/" element={<HomePage />} />
        <Route index path="/explore" element={<ExplorePage />} />

        <Route path="/activities" element={<AllActivitiesPage />} />
        <Route
          path="/activities/:activityId"
          element={<SingleActivityPage />}
        />

        <Route path="/explore-city" element={<ExploreCityPage />} />
        <Route path="/explore/:activityId" element={<SingleActivityPage />} />
        <Route
          path="/special-offer/:activityId"
          element={<SingleActivityPage />}
        />
        <Route
          path="/orders/previous/:activityId/:orderId"
          element={<SingleActivityReOrderPage />}
        />
        <Route
          path="/orders/upcoming/:activityId/:orderId"
          element={<SingleOrderCancelationPage />}
        />
        <Route path="/:activityId/rating" element={<ReviewInterfacePage />} />
        <Route path="/rating-review" element={<OrderReviewPage />} />
        <Route path="/order-details" element={<OrderDetailPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<ProfilePage />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/orders" element={<OrdersPage />}>
          <Route index element={<Navigate to="upcoming" replace />} />
          <Route path="previous" element={<OrdersPrevious />} />
          <Route path="upcoming" element={<OrdersUpcoming />} />
        </Route>
        <Route path="/support" element={<SupportPage />} />
        <Route path="/verify-otp" element={<VerifyOtpPage />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route
          path="/:activityId/booking/booking-information"
          element={<BookingInformation />}
        />
        <Route
          path="/:activityId/booking/registration"
          element={<BookingRegistrationPage />}
        />
        <Route
          path="/:activityId/booking/payment"
          element={<BookingPaymentPage />}
        />
      </Routes>
      {shouldShowBottomNavBar && <BottomNavBar />}
    </>
  );
};

const AppWrapper = () => {
  return (
    <LanguageProvider>
      <Router>
        <App />
      </Router>
    </LanguageProvider>
  );
};

export default AppWrapper;
