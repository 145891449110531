import React, { useEffect } from "react";
import PreviousCard from "../components/ordersComponents/PreviousCard";
import { Box, Button, Container } from "@mui/material";
import useFetchPreviousOrders from "../hooks/useFetchPreviousOrders";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OrdersPrevious() {
  useEffect(() => {
    document.title = "YallaFalla | Previous Orders";
  }, []);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { previousOrders, loading, error } = useFetchPreviousOrders();
  console.log(previousOrders);

  // loading handler
  if (loading) {
    return <h1>{t("Loading")}</h1>;
  }

  // error handler
  if (error) {
    return (
      <Container sx={{ paddingY: "50px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <h1>{t("OrderPageErrorMessage")}</h1>
          <Button
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: "#f26456",
              color: "white",
              borderRadius: 7,
              padding: "10px 70px",
              cursor: "pointer",
              border: "1px solid",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#f26456",
                color: "white",
              },
            }}
          >
            {t("SeeUpcomingEvents")}
          </Button>
        </Box>
      </Container>
    );
  }

  // no upcoming orders
  if (previousOrders.length <= 0) {
    return (
      <Container sx={{ paddingY: "50px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <h1>{t("NoPreviousYet")}</h1>
          <Button
            onClick={() => navigate("/orders/upcoming")}
            sx={{
              backgroundColor: "#f26456",
              color: "white",
              borderRadius: 7,
              padding: "10px 70px",
              cursor: "pointer",
              border: "1px solid",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#f26456",
                color: "white",
              },
            }}
          >
            {t("ExploreUpcomingEvents")}
          </Button>
        </Box>
      </Container>
    );
  }
  return (
    <Container sx={{ paddingY: "50px" }}>
      <Box
        sx={{
          display: "flex",
        flexDirection: "column",
          gap: 4,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {previousOrders?.map((order) => (
          <Link
            to={`/orders/previous/${order.activity_event}/${order.name}`}
            state={{ previousOrderStatus: order?.status }}
            style={{
              textDecoration: "none",
            }}
          >
            <PreviousCard
              key={order.name}
              image={order.image}
              name={order.event_title}
              city={order.city}
              companyName={order.company_name}
              country={order.country}
              date={order.date}
              id={order.name}
              price={order.total_price}
              time={order.time}
              status={order.status}
            />
          </Link>
        ))}
      </Box>
    </Container>
  );
}

export default OrdersPrevious;
