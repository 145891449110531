import React, { useState, useEffect } from "react";
import { Box, Button, Container, Typography, Checkbox } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import BookingPagesHeader from "../components/BookingPagesHeader";
import useFetchEventDetails from "../hooks/useFetchEventDetails";
import { format, isSameDay } from "date-fns";
import { useTranslation } from "react-i18next";

function BookingInformation() {
  const [counter, setCounter] = useState(1);
  const [ticketsLimit, setTicketsLimit] = useState(0);
  const [date, setDate] = useState(null);
  const [timeSelected, setTimeSelected] = useState("");
  const [timeNameSelected, setTimeNameSelected] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [filteredTimes, setFilteredTimes] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [totalPrice, setTotalPrice] = useState();
  const [buttonColor, setButtonColor] = useState("");

  const params = useParams();
  const { results } = useFetchEventDetails(params.activityId);

  //get the current location
  const location = useLocation();
  const path = location.pathname;
  const currentPage = path.split("/").pop();
  console.log("current page:", currentPage);

  const formData = location.state;

  useEffect(() => {
    if (results?.time_table) {
      const dates = results.time_table.map((time) => new Date(time.date));
      setAvailableDates(dates);
      console.log(dates);

      setTotalPrice(results.price);
    }
  }, [results]);

  useEffect(() => {
    if (date && results?.time_table) {
      const filtered = results?.time_table.filter(
        (time) =>
          format(new Date(time.date), "yyyy-MM-dd") ===
          format(date, "yyyy-MM-dd")
      );
      console.log(results.time_table);
      setFilteredTimes(filtered);
      console.log(format(date, "yyyy-MM-dd"));
      console.log(filtered);
    }
  }, [date, results]);

  const handleAddClick = () => {
    if (counter < ticketsLimit && timeSelected) {
      setCounter((prevCounter) => prevCounter + 1);
      setTotalPrice((prevPrice) => prevPrice + results.price);
    }
  };

  const handleRemoveClick = () => {
    if (counter > 0 && timeSelected) {
      setCounter((prevCounter) => prevCounter - 1);
      setTotalPrice((prevPrice) => prevPrice - results.price);
    }
  };

  const handleSelectedTime = (time, name, availableUnits) => {
    setTimeSelected(time);
    setTimeNameSelected(name);
    setTicketsLimit(availableUnits);
    setCounter(1); // Reset counter to 1 when a new time is selected
    setTotalPrice(results.price); // Reset total price to initial price
    setButtonColor("#84b4de");
  };
  console.log("time name selected :", timeNameSelected);

  const handleCheckboxChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const handleDateSelected = (newDate) => {
    setDate(newDate);
    setTimeSelected("");
    setTimeNameSelected("");
    setCounter(1);
  };

  const navigate = useNavigate();
  const handleNextButton = () => {
    navigate(`/${params.activityId}/booking/payment`, {
      state: {
        registerData: formData,
        date_selected: date,
        time_selected: timeSelected,
        ticket_count: counter,
        total_price: totalPrice,
      },
    });
  };

  const shouldDisableDate = (date) => {
    return !availableDates.some((availableDate) =>
      isSameDay(availableDate, date)
    );
  };

  // format time to 12 hour format
  const formatTime = (time24) => {
    const [hours, minutes] = time24.split(":");
    const period = +hours < 12 ? "AM" : "PM";
    const hours12 = +hours % 12 || 12;
    return `${hours12}:${minutes} ${period}`;
  };

  //translation
  const { t } = useTranslation();
  // disable button if tickets is less than 0 
  const isDisabled = (time) => time.available_units <= 0;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <BookingPagesHeader currentPage={currentPage} />
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            paddingBottom: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={date}
              onChange={handleDateSelected}
              shouldDisableDate={shouldDisableDate}
              sx={{
                direction:'ltr'
              }}
              // renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            {filteredTimes.map((time) => (
              <Button
                onClick={() =>
                  handleSelectedTime(
                    time?.time,
                    time?.name,
                    time?.available_units
                  )
                }
                key={time.name}
                sx={{
                  border: "1px solid #dfdfdf",
                  color: "black",
                  borderRadius: 2,
                  padding: "5px 20px",
                  cursor: "pointer",
                }}
                style={{
                  backgroundColor:
                    timeSelected === time?.time &&
                    timeNameSelected === time?.name
                      ? buttonColor
                      : "",
                }}
                disabled={isDisabled(time)}
              >
                {formatTime(time.time)} - {formatTime(time.end_time)}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              backgroundColor: "#84b4de",
              padding: "20px",
              border: "1px",
              borderRadius: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography color="white">{t("NumberOfTickets")}</Typography>
              {filteredTimes.map(
                (time) =>
                  time.name === timeNameSelected && (
                    <Typography key={time.name} color="white" fontSize="12px">
                      {time.available_units} {t("TicketsLeft")}
                    </Typography>
                  )
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <div onClick={handleAddClick}>
                <Box
                  sx={{
                    border: "1px solid white",
                    borderRadius: 2,
                    padding: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AddIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </Box>
              </div>
              <Typography color="white">{counter}</Typography>
              <div onClick={handleRemoveClick}>
                <Box
                  sx={{
                    border: "1px solid white",
                    borderRadius: 2,
                    padding: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RemoveIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </Box>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox checked={termsChecked} onChange={handleCheckboxChange} />
            <Typography color="#757f8d">
              {t("AgreeToTermsAndConditions")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "10px",
            }}
          >
            <Box>
              <Typography color="#757f8d">{t("Total")}</Typography>
              <Typography color="#f26456">{totalPrice}</Typography>
            </Box>
            <Button
              sx={{
                backgroundColor: "#f26456",
                color: "white",
                borderRadius: 7,
                padding: "10px 70px",
                cursor: "pointer",
                border: "1px solid",
              }}
              disabled={!termsChecked || timeSelected === "" || counter === 0}
              onClick={handleNextButton}
            >
              {t("NextButtonText")}
            </Button>
          </Box>
        </Container>
      </Box>
    </LocalizationProvider>
  );
}

export default BookingInformation;
