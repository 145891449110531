import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Link,
  Alert,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
// import { MuiTelInput } from "mui-tel-input";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import { signup } from "../services/api";
import EmailIcon from "@mui/icons-material/Email";

const RegistrationPage = () => {
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const goBackHome = () => {
    navigate("/");
  };

  const validate = () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address");
      return false;
    }
    setError("");
    return true;
  };

  const handleSendOTP = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");
    let valid = validate();
    if (!valid) {
      setLoading(false);
      return;
    }
    try {
      await signup(first_name, last_name, email, "Riyadh");
      setSuccess("OTP sent successfully.");
      navigate("/verify-otp", { state: { email } });
    } catch (error) {
      setError("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="80vh"
      >
        <Typography
          variant="h5"
          gutterBottom
          alignSelf={"start"}
          sx={{
            position: "absolute",
            top: "30px", // Adjust the vertical distance from the top
            left: "16px", // Adjust the horizontal distance from the right
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            <ChevronLeftIcon></ChevronLeftIcon>
          </IconButton>
          Registration
        </Typography>
        <Box sx={{ width: "100%" }}>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>
        <Box component="form" width="100%" mt={2} onSubmit={handleSendOTP}>
          {/* first name */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
              padding: "10px 0px",
              fontSize: "16px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              First Name
            </label>
            <TextField
              placeholder="First Name"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              margin="normal"
              required
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
              }}
            />
          </Box>
          {/* last name */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
              padding: "10px 0px",
              fontSize: "16px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              Last Name
            </label>
            <TextField
              placeholder="Last Name"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              margin="normal"
              required
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
              }}
            />
          </Box>

          {/* email */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
              padding: "10px 0px",
              fontSize: "16px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              Email
            </label>
            <TextField
              placeholder="Enter your email"
              fullWidth
              required
              value={email} // Using email state
              onChange={(event) => setEmail(event.target.value)}
              type="email"
              margin="normal"
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
                startAdornment: (
                  <InputAdornment>
                    <EmailIcon
                      sx={{ paddingRight: "10px", color: "#F26557" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <LoadingButton
            sx={{
              backgroundColor: "#F26456",
              borderRadius: "50px",
              marginY: "20px",
            }}
            fullWidth
            size="large"
            onClick={handleSendOTP}
            loading={loading}
            variant="contained"
            loadingPosition="start"
            startIcon={<SmsOutlinedIcon />}
          >
            <span>Send OTP</span>
          </LoadingButton>
          <Typography variant="body2" align="center">
            Already have an account?{" "}
            <Link
              style={{
                color: "#F07063",
                textDecoration: "none",
              }}
              href="/login"
              color="secondary"
            >
              Log In
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default RegistrationPage;
