import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import ActivityImageCarousel from "../components/activityComponents/ActivityImageCarousel";
import ActivityHeader from "../components/activityComponents/ActivityHeader";
import ActivityBookNowSection from "../components/activityComponents/ActivityBookNowSection";
import { useLocation, useParams } from "react-router-dom";
import useFetchSingleEventDetails from "../hooks/useFetchSingleEventDetails";
import styles from "./SingleActivityPage.module.css";
import { useTranslation } from "react-i18next";
import OrderReviewDialog from "./OrderReviewPage";
import ActivityTestimonial from "../components/activityComponents/ActivityTestimonial";
import CardCarouselSection from "../components/CardCarouselSection";
import useFetchPreviousOrders from "../hooks/useFetchPreviousOrders";

function SingleActivityReOrderPage() {
  const { activityId } = useParams();
  const { orderId } = useParams();
  const { eventDetails } = useFetchSingleEventDetails(activityId);
  console.log("eventDetails", eventDetails);
  const { previousOrders } = useFetchPreviousOrders();
  console.log("orders", previousOrders);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const order = previousOrders.find((o) => o.name === orderId);
  console.log("singleOrder", order);

  // scroll function

  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  const location = useLocation();
  const { previousOrderStatus } = location.state;
  console.log(previousOrderStatus);

  const images = [
    {
      id: "1",
      url: eventDetails?.image,
    },
    {
      id: "2",
      url: eventDetails?.image,
    },
    {
      id: "1",
      url: eventDetails?.image,
    },
    {
      id: "2",
      url: eventDetails?.image,
    },
  ];

  const handleReviewSubmit = () => {
    window.location.reload(); // Reload the page to trigger data re-fetch
  };

  // translation
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        marginBottom: "150px",
      }}
    >
      <ActivityImageCarousel images={images} onOpen={handleOpen} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          overflow: "hidden",
          marginTop: "20px",
        }}
      >
        {/* image carousel */}
        {/* activity details */}
        <ActivityHeader
          name={eventDetails?.title}
          description={eventDetails?.description}
          city={eventDetails?.address_title}
          country={eventDetails?.country}
          price={eventDetails?.price}
          locationUrl={eventDetails?.google_maps_location}
          orderName={orderId}
          category={eventDetails?.category}
          company={eventDetails?.company_name}
          status={previousOrderStatus}
        />
        {/* snapshots */}
        {console.log(eventDetails?.feedbacks?.feedback_image)}

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {eventDetails?.feedbacks?.some(
            (feedback) => feedback.feedback_image
          ) && (
            <>
              <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                {t("singleActivityPageSnapShotsTitle")}
              </Typography>
              <div className={styles.snapshotContainer} onWheel={handleScroll}>
                {eventDetails.feedbacks.map((feedback, index) =>
                  feedback.feedback_image ? (
                    <div className={styles.snapshotItem} key={index}>
                      <img
                        className={styles.snapshotImage}
                        src={feedback.feedback_image}
                        alt="snapshot"
                      />
                    </div>
                  ) : null
                )}
              </div>
            </>
          )}
        </Box>

        {/* testimonials */}
        {order?.rating && (
          <Box>
            <CardCarouselSection title={"Ratings & Reviews "} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {order?.rating && (
                <ActivityTestimonial
                  customerName={localStorage.getItem("customer_name")}
                  feedback={order.feedback}
                  rating={order.rating}
                  key={order.name}
                  reviewTime={order.formatted_review_time}
                />
              )}
            </Box>
          </Box>
        )}
        {/* book now */}
        <OrderReviewDialog
          open={open}
          onClose={handleClose}
          eventId={activityId}
          orderId={orderId}
          onReviewSubmit={handleReviewSubmit}
        />
        <ActivityBookNowSection title={t("ReORderButtonText")} />
      </Container>
    </Box>
  );
}

export default SingleActivityReOrderPage;
