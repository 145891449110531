import { createContext, useContext, useEffect, useState } from "react";
import i18n from "../utils/i18";

export const LanguageContext = createContext();

export const LanguageProvider = ({children}) =>{

    const [language, setLanguage] = useState(localStorage.getItem('language'));

    useEffect(()=>{
        i18n.changeLanguage(language);
        document.body.setAttribute('dir', language === 'en' ? 'ltr' : language === 'ar' ? 'rtl' : 'ltr');
    },[language])

const changeLanguage = (lng) =>{
        setLanguage(lng);
        localStorage.setItem('language',lng);
    }

    return(
        <LanguageContext.Provider value={{language, changeLanguage}}>
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguage = () => useContext(LanguageContext);
