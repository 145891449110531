import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../store/userSlice";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import {LanguageContext} from '../services/LanguageContext'
function ProfilePage() {
  //   const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const navigate = useNavigate(); // Hook to programmatically navigate between routes
  const userName = localStorage.getItem("customer_name");
  const dispatch = useDispatch();
  // translation
  const { t } = useTranslation();
  
  const {changeLanguage} = useContext(LanguageContext);
  const language = localStorage.getItem("language");

  // const handleLanguageChange = (lng) => {
  //   i18n.changeLanguage(lng);
  //   if (lng === "ar") {
  //     document.body.setAttribute("dir", "rtl");
  //     setSelectedLanguage("Arabic");
  //   } else {
  //     document.body.setAttribute("dir", "ltr");
  //     setSelectedLanguage("English");
  //   }
  // };

  const handleLogout = () => {
    dispatch(logout(false));
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    document.title = "YallaFalla | Logout";
  }, []);


  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        gap={3}
      >
        {/* avatar & user name container */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Avatar src="" />
          <Typography>{userName}</Typography>
        </Box>
        {/* actions container */}
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          padding={2}
          bgcolor="#EAEAEA"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                padding: "10px",
                backgroundColor: "rgba(244,122,106,0.37)",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LanguageIcon sx={{
                color:"#f26456"
              }}/>
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "#333333",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {t('Language')}
            </Typography>
          </Box>

          <ButtonGroup
            sx={{
              backgroundColor: "white",
              padding: "8px",
              borderRadius: "10px",
              width: "40%",
            }}
            variant="contained"
            color="primary"
          >
            <Button
              style={{
                backgroundColor:
                  language === "en" ? "#F47A6A" : "transparent",
                color: language === "ar" ? "black" : "",
                borderRadius: "10px",
                border: "none",
                fontSize: "12px",
              }}
              onClick={() => changeLanguage("en")}
            >
              English
            </Button>
            <Button
              style={{
                backgroundColor:
                  language === "ar" ? "#F47A6A" : "transparent",
                color: language === "en" ? "black" : "",
                borderRadius: "10px",
                border: "none",
                fontSize: "12px",
              }}
              onClick={() => changeLanguage("ar")}
            >
              العربيه
            </Button>
          </ButtonGroup>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "start",
            width: "100%",
          }}
        >
          <Typography variant="h5" gutterBottom alignSelf={"start"}>
            <IconButton aria-label="back" onClick={goBackHome}>
              <ChevronLeftIcon></ChevronLeftIcon>
            </IconButton>
            {t("LogoutTitle")}
          </Typography>
        </Box> */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <LoadingButton
            size="large"
            onClick={handleLogout}
            sx={{ mt: 2, mb: 2, backgroundColor: "transparent" }}
            loadingPosition="start"
          >
            <span
              style={{
                color: "#f47a6a",
                fontWeight: "bold",
              }}
            >
              {t("LogoutButtonTitle")}
            </span>
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}

export default ProfilePage;
